import { Box, Typography } from '@mui/material';
import { Header, RegularButton } from 'components';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTabs from 'components/CustomTabs';
import { ORDERS_TAB } from 'model/Entities';
import OrdersTable from './components/OrdersTable';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import moment from 'moment';
import { syncDear } from 'api/order';
import { useSnackbar } from 'notistack';

const Orders = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [dearSyncing, setDearSyncing] = useState(false);
  const [totalResolved, setTotalResolved] = useState(0);
  const [totalStopCheck, setTotalStopCheck] = useState(0);
  const [time, setTime] = useState<number>(0);

  const pendingTableRef: any = useRef();
  const batchedTableRef: any = useRef();
  const resolvedTableRef: any = useRef();
  const stopCheckTableRef: any = useRef();
  const deletedTableRef: any = useRef();

  useEffect(() => {
    setBreadcrumb([{ label: 'Orders' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const formatTime = useCallback(() => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    if (minutes <= 0 && seconds <= 0) return '';
    return `Timer: ${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, [time]);

  const lastSyncDateTime = localStorage.getItem('last_sync_dear');
  useEffect(() => {
    const nextSync = moment(localStorage.getItem('last_sync_dear')).add(1, 'minute');
    const nextSyncDate = new Date(nextSync.format('MMMM D YYYY HH:mm:ss'));
    const currentDate = new Date();
    const differenceInMs = nextSyncDate.getTime() - currentDate.getTime();
    const remainingSeconds = Math.floor(differenceInMs / 1000);

    if (remainingSeconds > 0) {
      setTime(remainingSeconds);
    }
  }, [lastSyncDateTime]);

  const syncDearSystem = async () => {
    try {
      const lastSync = localStorage.getItem('last_sync_dear');

      if (lastSync) {
        if (moment(lastSync).fromNow() === 'a few seconds ago') {
          enqueueSnackbar('Please wait at least 1 minute before syncing again.', { variant: 'info' });
          return;
        }
      }

      setDearSyncing(true);
      await syncDear();
      localStorage.setItem('last_sync_dear', moment().format('MMMM D YYYY HH:mm:ss'));
      enqueueSnackbar(`Done syncing with DEAR System`, { variant: 'success' });
      pendingTableRef.current.refreshTable();
    } catch (error) {
      enqueueSnackbar(`Error in syncing with DEAR System`, { variant: 'error' });
    } finally {
      setDearSyncing(false);
    }
  };

  return (
    <Box>
      <Box display="flex" gap="10px" justifyContent="space-between" alignItems="flex-start" width="100%">
        <Header title="Orders" />
        <Box display="flex" flexDirection="column" alignItems="center">
          <RegularButton
            color="info"
            startIcon={<SyncOutlinedIcon />}
            label="Sync DEAR"
            onClick={() => syncDearSystem()}
            loading={dearSyncing}
            loadingText="Syncing, please wait ..."
            disabled={formatTime() !== ''}
          />
          <Typography fontWeight="bold">{formatTime()}</Typography>
        </Box>
      </Box>
      <CustomTabs
        tabs={[
          {
            label: 'Pending',
            content: (
              <OrdersTable
                status={ORDERS_TAB.PENDING}
                ref={pendingTableRef}
                batchedTableRef={batchedTableRef}
                stopCheckTableRef={stopCheckTableRef}
                deletedTableRef={deletedTableRef}
              />
            ),
          },
          { label: 'Batched', content: <OrdersTable status={ORDERS_TAB.BATCHED} ref={batchedTableRef} /> },
          {
            label: `Resolved (${totalResolved})`,
            preLoad: true,
            content: (
              <OrdersTable
                status={ORDERS_TAB.RESOLVED}
                setTotalCount={setTotalResolved}
                ref={resolvedTableRef}
                batchedTableRef={batchedTableRef}
                stopCheckTableRef={stopCheckTableRef}
              />
            ),
          },
          {
            label: `Stop Check (${totalStopCheck})`,
            preLoad: true,
            content: (
              <OrdersTable
                status={ORDERS_TAB.STOP_CHECK}
                setTotalCount={setTotalStopCheck}
                ref={stopCheckTableRef}
                resolvedTableRef={resolvedTableRef}
              />
            ),
          },
          {
            label: `Partial Fulfillment`,
            content: <OrdersTable status={ORDERS_TAB.PARTIAL_FULFILLMENT} />,
          },
          {
            label: `Removed`,
            content: (
              <OrdersTable status={ORDERS_TAB.DELETED} ref={deletedTableRef} pendingTableRef={pendingTableRef} />
            ),
          },
        ]}
      />
    </Box>
  );
};

export default Orders;
