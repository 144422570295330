import { Box, Typography } from '@mui/material';
import {
  CustomGridCell,
  CustomModal,
  CustomSelectField,
  CustomTable,
  CustomTextField,
  Header,
  RegularButton,
} from 'components';
import { getShippingSummary, markAsShipped } from 'api/order';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ApiQuery } from 'model/interface';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { UserContext } from 'context/user.context';
import { formatDateWithDay } from 'utils';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';

type OrderToShip = {
  carrier_id: string;
  order_name: string;
  tracking_number: string;
};

const ShippingStation = () => {
  const colors = tokens();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [orders, setOrders] = useState<OrderToShip[]>([]);
  const [newRowCount, setNewRowCount] = useState<number>();
  const [summary, setSummary] = useState({ shipped: [] as any[], not_shipped: [] as any[] });
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openShippingSummary, setOpenShippingSummary] = useState(false);

  const [rows, setRows] = useState<any[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);
  const rowCountDefault = 10;

  useEffect(() => {
    setBreadcrumb([{ label: 'Shipping Station' }]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewRows = () => {
    setOrders((prev) => [
      ...prev,
      ...Array.from(Array(newRowCount).keys()).map((i) => ({ carrier_id: '', order_name: '', tracking_number: '' })),
    ]);
    setNewRowCount(0);
  };

  const applyCarrierToAll = (carrier_id: string) => {
    if (carrier_id) {
      setOrders((prev) => {
        prev.forEach((order) => (order.carrier_id = carrier_id));
        return [...prev];
      });
      setTimeout(() => updateOrdersInLocalStorage(), 400);
    }
  };

  const clearRows = () => {
    setOrders([]);
    clearOrdersInLocalStorage();
    setTimeout(() => {
      setOrders((prev) =>
        Array.from(Array(rowCountDefault).keys()).map((i) => ({ carrier_id: '', order_name: '', tracking_number: '' }))
      );
    }, 200);
  };

  const [inputValues, setInputValues] = useState<string[]>(Array(8).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, orders.length * 2);
  }, [orders]);

  useEffect(() => {
    const items = localStorage.getItem('shipping_orders');
    const ordersLocal: OrderToShip[] = JSON.parse(items ?? '[]');
    if (items?.length) {
      if (firstLoad) {
        setOrders(ordersLocal);
        setFirstLoad(false);
      }
    } else {
      setOrders(
        Array.from(Array(rowCountDefault).keys()).map((i) => ({ carrier_id: '', order_name: '', tracking_number: '' }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('shipping_orders')]);

  const handleInputChange = (index: number, value: string) => {
    const newValues = [...inputValues];
    newValues[index] = value;
    setInputValues(newValues);
    updateOrdersInLocalStorage();

    if (inputRefs.current[index]) {
      clearTimeout((inputRefs.current[index] as any).timer);
    }

    (inputRefs.current[index] as any).timer = setTimeout(() => {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }, 1000);
  };

  const handleFocus = (index: number) => {
    inputRefs.current[index]?.select();
  };

  const updateOrdersInLocalStorage = () => {
    localStorage.setItem('shipping_orders', JSON.stringify(orders));
  };

  const clearOrdersInLocalStorage = () => {
    localStorage.removeItem('shipping_orders');
  };

  const handleMarkAsShipped = async () => {
    const with_inputs = orders.filter((order) => order.order_name);
    if (with_inputs.length) {
      setLoading(true);
      markAsShipped(with_inputs)
        .then((res) => {
          setOpenSummaryModal(true);
          setSummary(res.data);
        })
        .finally(() => setLoading(false));
    } else {
      enqueueSnackbar(`There are no orders to process. Please fill in the form.`, { variant: 'error' });
    }
  };

  const setShippingSummary = async (query: ApiQuery) => {
    const fetchData = async () => {
      setLoadingTable(true);
      try {
        const res = await getShippingSummary();
        const groupedData: any = res.data.reduce((acc: any, item: any) => {
          const date = formatDateWithDay(item.shipping_date, 'No Date'); // Extract the date part only
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(item);
          return acc;
        }, {});

        const final = Object.keys(groupedData).map((key) => {
          const carriers: any = groupedData[key];
          let total = 0;
          const carrier_data: any[] = carriers.reduce((result: any, carrier: any) => {
            if (carrier.shipping_carrier) {
              result[carrier.shipping_carrier.carrier_name] = carrier.total_orders;
              total += parseInt(carrier.total_orders);
            }
            return result;
          }, {});
          return {
            date: key,
            total,
            ...carrier_data,
          };
        });
        setRows(final);
        // setRowCount(final);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      } finally {
        setLoadingTable(false);
      }
    };

    fetchData();
  };

  const columns = useMemo(() => {
    if (user) {
      const carriers = user.shipping_carriers.map((carrier) => ({
        field: carrier.carrier_name,
        headerName: carrier.carrier_name,
        flex: 1,
        renderCell: ({ value }: any) => <CustomGridCell>{value ?? '0'}</CustomGridCell>,
      }));
      return [
        {
          field: 'date',
          headerName: 'Date of Shipping',
          flex: 1,
        },
        ...carriers,
        {
          field: 'total',
          headerName: 'Total',
          flex: 1,
        },
      ];
    }
    return [];
  }, [user]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Header title="Shipping Station" />
        <RegularButton
          label="View Shipping Summary"
          onClick={() => setOpenShippingSummary(true)}
          startIcon={<RemoveRedEyeOutlinedIcon />}
        />
      </Box>

      <Box display="flex" gap="20px" alignItems="flex-start" position="relative">
        <Box display="flex" flexDirection="column" gap="10px" width="75%">
          <Box
            paddingX="20px"
            display="flex"
            gap="20px"
            width="100%"
            alignItems="center"
            sx={{ borderRadius: '10px', '& p': { fontWeight: 'bold' } }}
          >
            <Typography flex={0.5}>#</Typography>
            <Typography flex={2}>Carrier</Typography>
            <Typography flex={0.8}></Typography>
            <Typography flex={2}>Order No.</Typography>
            <Typography flex={2}>Tracking Number</Typography>
          </Box>
          {orders.map((order, index) => (
            <Box
              key={index}
              display="flex"
              gap="20px"
              width="100%"
              alignItems="center"
              sx={{ border: '2px solid ' + colors.secondary_background, padding: '20px', borderRadius: '10px' }}
            >
              <Typography flex={0.5}>{index + 1}</Typography>
              <Box flex={2}>
                <CustomSelectField
                  value={order.carrier_id}
                  options={[
                    ...user?.shipping_carriers,
                    // { carrier_name: SHIPPING_COMPANY.SINGAPORE_POST, id: SHIPPING_COMPANY_ID.SINGAPORE_POST },
                  ].map((carrier) => ({
                    key: carrier.carrier_name,
                    value: carrier.id + '',
                  }))}
                  label={'Carrier'}
                  fieldName={'carrier'}
                  handleChange={(e) => {
                    order.carrier_id = e.target.value;
                    updateOrdersInLocalStorage();
                  }}
                />
                <Typography></Typography>
              </Box>
              <Typography
                flex={0.8}
                fontSize="12px"
                fontWeight="bold"
                onClick={() => applyCarrierToAll(order.carrier_id)}
                sx={{
                  color: colors.accent,
                  marginLeft: '-10px',
                  cursor: 'pointer',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                Apply to all
              </Typography>
              <Box flex={2}>
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index * 2] = el)}
                  type="text"
                  defaultValue={order.order_name}
                  onChange={(e) => {
                    order.order_name = e.target.value;
                    handleInputChange(index * 2, e.target.value);
                  }}
                  onFocus={() => handleFocus(index * 2)}
                  placeholder="Order No."
                  style={{
                    backgroundColor: colors.inputBackground,
                    border: 'none',
                    borderRadius: '10px',
                    padding: '15px 16px',
                    color: colors.text,
                    fontSize: '16px',
                  }}
                />
              </Box>
              <Box flex={2}>
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index * 2 + 1] = el)}
                  type="text"
                  defaultValue={order.tracking_number}
                  onChange={(e) => {
                    order.tracking_number = e.target.value;
                    handleInputChange(index * 2 + 1, e.target.value);
                  }}
                  onFocus={() => handleFocus(index * 2 + 1)}
                  placeholder="Tracking Number"
                  style={{
                    backgroundColor: colors.inputBackground,
                    border: 'none',
                    borderRadius: '10px',
                    padding: '15px 16px',
                    color: colors.text,
                    fontSize: '16px',
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          width="25%"
          display="flex"
          flexDirection="column"
          gap="20px"
          height="calc(100vh - 200px)"
          justifyContent="space-between"
          sx={{ position: 'sticky', top: '50px' }}
        >
          <Box>
            <RegularButton label="Clear" onClick={clearRows} variant="outlined" color="info" />
            <Box display="flex" alignItems="center" width="100%" gap="10px" mt="20px">
              <Typography>Add more rows:</Typography>
              <CustomTextField
                label="Row Count"
                fieldName="count"
                sx={{ width: '100px' }}
                value={newRowCount}
                type="number"
                handleChange={(event) => setNewRowCount(parseInt(event.target.value))}
              />
              <RegularButton label="Add" onClick={addNewRows} disabled={!newRowCount} />
            </Box>
          </Box>
          <RegularButton label="Mark as shipped" onClick={handleMarkAsShipped} loading={loading} />
        </Box>

        <CustomModal header={'Summary'} open={openSummaryModal} setOpen={setOpenSummaryModal}>
          {summary && (
            <Box display="flex" gap="20px" width="100%">
              <Box flex="1">
                <Typography variant="h4" fontWeight="bold" color={colors.greenAccent}>
                  Total Shipped Orders:
                </Typography>
                <Typography fontWeight="bold" fontSize="30px">
                  {summary.shipped?.length}
                </Typography>
                <Box>
                  {summary.shipped?.map((order, index) => (
                    <Typography key={index}>
                      {order.order_name} - {order.tracking_number}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box flex="1">
                <Typography variant="h4" fontWeight="bold" color={colors.redAccent}>
                  Total Orders With Errors:
                </Typography>
                <Typography fontWeight="bold" fontSize="30px">
                  {summary.not_shipped?.length}
                </Typography>
                <Box>
                  {summary.not_shipped?.map((order, index) => (
                    <Typography key={index}>
                      {order.order_name} - {order.error}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
          <Box display="flex" justifyContent="center" mt="20px">
            <RegularButton
              label="Close and Clear"
              onClick={() => {
                setOpenSummaryModal(false);
                clearRows();
                clearOrdersInLocalStorage();
              }}
              variant="outlined"
              color="info"
            />
          </Box>
        </CustomModal>

        <CustomModal
          header={'Shipping Summary'}
          open={openShippingSummary}
          setOpen={setOpenShippingSummary}
          width={1000}
        >
          <CustomTable
            rows={rows}
            rowCount={0}
            columns={columns}
            getData={setShippingSummary}
            loading={loadingTable}
            hideSearch
            rowId="date"
            hideFooterPagination
          />
        </CustomModal>
      </Box>
    </Box>
  );
};

export default ShippingStation;
